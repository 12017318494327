<template>
	<DaModalFrame v-model:open="modalIsOpen">
		<CreateStudent
			v-if="modalType === modalTypes.ADD"
			v-model:error="error"
			v-model:inProgress="inProgress"
			@close="closeModal"
			@submit="submitStudent"
		/>
		<CreateStaffModal
			v-if="modalType === modalTypes.CREATE"
			@close="closeModal"
			@submit="createStaffSubmit"
			v-model:data="staffsList"
		/>
		<InviteParentPopup
			v-if="modalType === modalTypes.INVITE && studentFullName"
			@close="closeModal"
			:student-name="studentFullName"
			:id="id"
		/>
		<ChangeStatus
			v-if="modalType === modalTypes.CHANGE"
			@close="closeModal"
			:student="studentData"
			@block="(data) => changeStatusStudent(false, data)"
			@active="(data) => changeStatusStudent(true, data)"
		/>
	</DaModalFrame>
	<div class="__record px-10 md-down:px-4 pb-30">
		<div
			class="
				__record__header
				pt-10
				pb-10
				flex
				md-down:flex-col
				justify-between
			"
		>
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
				"
			>
				<DaIcon class="mr-3 h-6 w-6" name="das--person" />
				Hồ sơ học sinh
			</div>
			<div class="flex items-center justify-end">
				<button
					v-if="isAdmin"
					class="button-p text-center w-32"
					type="button"
					@click="openCreateModal"
				>
					Thêm học sinh
				</button>
			</div>
		</div>
		<div v-if="!isLoading" class="__record__content">
			<div
				class="
					__record__intro
					flex flex-nowrap
					items-end
					justify-between
					gap-8
					xl-down:flex-col xl-down:items-stretch
				"
			>
				<div
					class="
						grid grid-cols-3
						sm-down:grid-cols-1
						items-stretch
						gap-8
						lg:w-4/5
						xl-down:w-full
					"
					v-if="centerState !== 'community'"
				>
					<div
						:class="{
							'border border-yellow-500 bg-yellow-50':
								tab === 'active',
						}"
						class="
							__record__intro-section
							py-4
							px-6
							shadow-sm
							rounded-xl
							w-full
							bg-white
							border
							hover:border-yellow-500
							cursor-pointer
							flex
							items-center
							justify-between
						"
						@click="changeStatus('active')"
					>
						<div class="text-neutral-500 text-sm flex items-center">
							<svg
								class="mr-2 text-green-500"
								fill="none"
								height="20"
								viewBox="0 0 20 20"
								width="20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7 9.75C6.31 9.75 5.75 10.31 5.75 11C5.75 11.69 6.31 12.25 7 12.25C7.69 12.25 8.25 11.69 8.25 11C8.25 10.31 7.69 9.75 7 9.75ZM13 9.75C12.31 9.75 11.75 10.31 11.75 11C11.75 11.69 12.31 12.25 13 12.25C13.69 12.25 14.25 11.69 14.25 11C14.25 10.31 13.69 9.75 13 9.75ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 9.71 2.02 9.42 2.05 9.14C4.41 8.09 6.28 6.16 7.26 3.77C9.07 6.33 12.05 8 15.42 8C16.2 8 16.95 7.91 17.67 7.74C17.88 8.45 18 9.21 18 10C18 14.41 14.41 18 10 18Z"
									fill="currentColor"
								/>
								<path
									d="M8 13.9895C8 13.9895 9.86667 18.4895 12 13.9895H8Z"
									fill="currentColor"
								/>
							</svg>
							Đang học
						</div>
						<div class="font-bold text-4xl text-neutral-700">
							{{ count.active }}
						</div>
					</div>
					<div
						:class="{
							'border border-yellow-500 bg-yellow-50':
								tab === 'pending',
						}"
						class="
							__record__intro-section
							py-4
							px-6
							shadow-sm
							rounded-xl
							w-full
							border
							bg-white
							hover:border-yellow-500
							cursor-pointer
							flex
							items-center
							justify-between
						"
						@click="changeStatus('pending')"
					>
						<div class="text-neutral-500 text-sm flex items-center">
							<svg
								class="mr-2 text-yellow-500"
								fill="none"
								height="20"
								viewBox="0 0 20 20"
								width="20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7 9.75C6.31 9.75 5.75 10.31 5.75 11C5.75 11.69 6.31 12.25 7 12.25C7.69 12.25 8.25 11.69 8.25 11C8.25 10.31 7.69 9.75 7 9.75ZM13 9.75C12.31 9.75 11.75 10.31 11.75 11C11.75 11.69 12.31 12.25 13 12.25C13.69 12.25 14.25 11.69 14.25 11C14.25 10.31 13.69 9.75 13 9.75ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 9.71 2.02 9.42 2.05 9.14C4.41 8.09 6.28 6.16 7.26 3.77C9.07 6.33 12.05 8 15.42 8C16.2 8 16.95 7.91 17.67 7.74C17.88 8.45 18 9.21 18 10C18 14.41 14.41 18 10 18Z"
									fill="currentColor"
								/>
								<path
									d="M8 13.9895C8 13.9895 9.86667 18.4895 12 13.9895H8Z"
									fill="currentColor"
								/>
							</svg>
							Chưa nhập học
						</div>
						<div class="font-bold text-4xl text-neutral-700">
							{{ count.pending }}
						</div>
					</div>

					<div
						:class="{
							'border border-yellow-500 bg-yellow-50':
								tab === 'locked',
						}"
						class="
							__record__intro-section
							py-4
							px-6
							shadow-sm
							rounded-xl
							w-full
							bg-white
							border
							hover:border-yellow-500
							cursor-pointer
							flex
							items-center
							justify-between
						"
						@click="changeStatus('locked')"
					>
						<div class="text-neutral-500 text-sm flex items-center">
							<svg
								class="mr-2 text-sienna-500"
								fill="none"
								height="20"
								viewBox="0 0 20 20"
								width="20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7 9.75C6.31 9.75 5.75 10.31 5.75 11C5.75 11.69 6.31 12.25 7 12.25C7.69 12.25 8.25 11.69 8.25 11C8.25 10.31 7.69 9.75 7 9.75ZM13 9.75C12.31 9.75 11.75 10.31 11.75 11C11.75 11.69 12.31 12.25 13 12.25C13.69 12.25 14.25 11.69 14.25 11C14.25 10.31 13.69 9.75 13 9.75ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 9.71 2.02 9.42 2.05 9.14C4.41 8.09 6.28 6.16 7.26 3.77C9.07 6.33 12.05 8 15.42 8C16.2 8 16.95 7.91 17.67 7.74C17.88 8.45 18 9.21 18 10C18 14.41 14.41 18 10 18Z"
									fill="currentColor"
								/>
								<path
									d="M8 13.9895C8 13.9895 9.86667 18.4895 12 13.9895H8Z"
									fill="currentColor"
								/>
							</svg>
							Đã khoá
						</div>
						<div class="font-bold text-4xl text-neutral-700">
							{{ count.locked }}
						</div>
					</div>
				</div>
				<div class="search-input">
					<div class="input-group input--lg">
						<input
							v-model="searchQuery"
							class="bg-white input-with-icon"
							placeholder="Gõ tên học sinh để bắt đầu tìm kiếm"
							type="text"
							@keyup="searching"
							@keypress.enter="search"
						/>
						<div class="input-icon absolute top-1/2">
							<DaIcon
								class="h-4 w-4 text-neutral-400"
								name="das--search"
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="status !== 'pending'"
				class="border-b border-neutral-200 mt-10 flex gap-6"
			>
				<div
					class="tab"
					v-for="i in tab == 'active'
						? tabs.filter(
								(t) =>
									t.type == 'active' &&
									numberOfStudent(t.value) > 0
						  )
						: tabs.filter(
								(t) =>
									t.type == 'locked' &&
									numberOfStudent(t.value) > 0
						  )"
					:key="i.value"
				>
					<div
						@click="status = i.value"
						class="
							order-transparent
							hover:text-yellow-700 hover:border-yellow-500
							whitespace-nowrap
							pb-6
							pt-4
							px-1
							font-semibold
							cursor-pointer
						"
						:class="
							status === i.value
								? 'border-b-2 text-yellow-700 border-yellow-500'
								: 'text-neutral-500'
						"
					>
						{{ i.label }} ({{ numberOfStudent(i.value) }})
					</div>
				</div>
			</div>
			<div
				v-if="studentsFilter.length"
				class="shadow-sm sm:rounded-2xl mt-12"
			>
				<div class="sm:rounded-t-2xl w-fit min-w-full">
					<table class="min-w-full divide-y text-neutral-200">
						<thead class="bg-neutral-100 h-16">
							<tr>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										w-16
										whitespace-nowrap
									"
									scope="col"
								>
									STT
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
										w-32
									"
									scope="col"
								>
									Tên học sinh
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
										w-32
									"
									scope="col"
								>
									TRỊ LIỆU VIÊN
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Tuổi
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Mô hình
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Phụ Huynh
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Trạng thái
								</th>
								<th class="px-6 py-3" scope="col"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(person, personIdx) in studentsFilter"
								:key="person.id"
								:class="
									personIdx % 2 === 0
										? 'bg-white'
										: 'bg-neutral-50'
								"
								class="
									text-neutral-700
									hover:bg-orange-50
									cursor-pointer
								"
								@click="
									$router.push(
										`/record/student/${person.id}/info`
									)
								"
							>
								<td>
									<div class="px-6 py-4 text-neutral-700">
										{{ personIdx + 1 }}
									</div>
								</td>
								<td
									class="px-6 py-4 text-neutral-700"
									style="width: 250px"
								>
									<div class="flex items-center">
										<div class="relative">
											<img
												v-if="
													person?.profile_photo?.w200
												"
												:src="
													person?.profile_photo?.w200
												"
												alt=""
												class="
													shadow-sm
													inline-block
													h-6
													w-6
													rounded-full
													flex-1
												"
											/>
											<span
												v-else
												:style="{
													background:
														person?.profile_photo
															?.default?.color,
												}"
												class="
													inline-flex
													items-center
													justify-center
													h-6
													w-6
													rounded-full
												"
											>
												<span
													class="
														font-medium
														leading-none
														text-white text-xs
													"
													>{{
														person?.profile_photo
															?.default?.initial
													}}</span
												>
											</span>
											<div
												v-if="person.state !== 'active'"
												class="
													absolute
													transform
													-translate-x-1/2
													-translate-y-1/2
													top-1/2
													left-1/2
													h-6
													w-6
													rounded-full
													flex
													items-center
													justify-center
												"
												style="
													background-color: rgba(
														241,
														162,
														103,
														0.5
													);
												"
											>
												<svg
													v-if="
														person.state ===
														'pending'
													"
													width="14"
													height="14"
													viewBox="0 0 14 14"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M13.4615 7.00011C13.4615 10.5687 10.5686 13.4617 6.99999 13.4617C3.43138 13.4617 0.538452 10.5687 0.538452 7.00011C0.538452 3.4315 3.43138 0.538574 6.99999 0.538574C10.5686 0.538574 13.4615 3.4315 13.4615 7.00011ZM6.99999 3.3655C6.99999 3.14246 6.81918 2.96165 6.59614 2.96165C6.37311 2.96165 6.1923 3.14246 6.1923 3.3655V7.80781C6.1923 7.95273 6.26995 8.08654 6.39578 8.15844L9.2227 9.77383C9.41636 9.88449 9.66305 9.81721 9.7737 9.62355C9.88436 9.4299 9.81708 9.18321 9.62343 9.07255L6.99999 7.57344V3.3655Z"
														fill="white"
													/>
												</svg>

												<svg
													v-else
													fill="none"
													height="14"
													viewBox="0 0 40 40"
													width="14"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M9.84364 16.0997V10.2496C9.84364 4.86466 14.3907 0.5 20 0.5C25.6093 0.5 30.1564 4.865 30.1564 10.2496V16.0997C33.5218 16.0997 36.25 18.7188 36.25 21.9497V33.6499C36.25 36.8812 33.5218 39.5 30.1564 39.5H9.84364C6.47821 39.5 3.75 36.8812 3.75 33.6499V21.9497C3.75 18.7188 6.47821 16.0997 9.84364 16.0997V16.0997ZM26.0936 16.0997V10.2496C26.0936 7.01866 23.3654 4.3995 20 4.3995C16.6346 4.3995 13.9064 7.01866 13.9064 10.2496V16.0997H26.0936ZM20 23.9C21.1218 23.9 22.0311 24.7729 22.0311 25.8499V29.7501C22.0311 30.8271 21.1218 31.7 20 31.7C18.8782 31.7 17.9689 30.8271 17.9689 29.7501V25.8499C17.9689 24.7729 18.8782 23.9 20 23.9Z"
														fill="#FAF5F1"
														fill-rule="evenodd"
													/>
												</svg>
											</div>
										</div>
										<div
											class="
												ml-4
												text-orange-600
												font-semibold
												hover:underline
											"
											style="max-width: 150px"
										>
											{{ person.name }}
										</div>
									</div>
								</td>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div
										v-if="
											person &&
											person?.assigned_staffs &&
											person?.assigned_staffs.length > 0
										"
									>
										<div
											v-for="staff in person?.assigned_staffs"
											:key="staff.id"
											:class="{
												'cursor-pointer':
													isAdminAndAccountant,
											}"
											@click="
												isAdminAndAccountant &&
													$router.push(
														`/record/employee/${staff.id}`
													)
											"
										>
											<div class="flex items-center mb-2">
												<img
													v-if="
														staff?.profile_photo
															?.w200
													"
													:src="
														staff?.profile_photo
															?.w200
													"
													alt=""
													class="
														shadow-sm
														inline-block
														h-6
														w-6
														rounded-full
													"
												/>
												<span
													v-else
													:style="{
														background:
															staff?.profile_photo
																?.default
																?.color,
													}"
													class="
														inline-flex
														items-center
														justify-center
														h-6
														w-6
														rounded-full
													"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															staff?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
												<div
													class="
														ml-4
														text-blue-600
														font-semibold
														whitespace-pre-wrap
														w-40
													"
												>
													{{ staff?.name }}
												</div>
											</div>
										</div>
									</div>
								</td>
								<td>
									<div
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										{{ person.age }}
									</div>
								</td>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
									"
									style="min-width: 156px"
								>
									<div class="flex items-center">
										<div
											v-if="person?.has_personal_class"
											class="p-2"
										>
											<img
												alt=""
												class="w-5 h-5"
												src="@/assets/images/icons/class-teacher.png"
											/>
										</div>
										<div
											v-if="person?.has_day_care_class"
											class="p-2"
										>
											<img
												alt=""
												class="w-5 h-5"
												src="@/assets/images/icons/spoon.png"
											/>
										</div>
										<div
											v-if="person?.has_group_class"
											class="p-2"
										>
											<img
												alt=""
												class="w-5 h-5"
												src="@/assets/images/icons/group.png"
											/>
										</div>
									</div>
								</td>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div
										v-if="
											person.number_of_parents_converted &&
											person.number_of_parents
										"
									>
										{{ person.number_of_parents_converted }}
										/
										{{ person.number_of_parents }}
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div
										v-if="centerState === 'community'"
										class="flex items-center gap-2"
									>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM15.0379 6.21209C14.6718 5.84597 14.0782 5.84597 13.7121 6.21209C13.7032 6.22093 13.6949 6.23029 13.6872 6.24013L9.34673 11.7709L6.72983 9.15403C6.36372 8.78791 5.77012 8.78791 5.40401 9.15403C5.03789 9.52015 5.03789 10.1137 5.40401 10.4799L8.71207 13.7879C9.07818 14.154 9.67178 14.154 10.0379 13.7879C10.046 13.7798 10.0537 13.7712 10.0609 13.7622L15.0512 7.52434C15.404 7.15727 15.3995 6.57371 15.0379 6.21209Z"
												fill="#41BEAD"
											/>
										</svg>
										<div>Đang học</div>
									</div>
									<StudentState
										v-else
										:state="person.state"
									/>
								</td>
								<td>
									<Menu as="div" class="ml-3 relative w-max">
										<div>
											<MenuButton
												class="
													rounded-full
													flex
													text-sm
													focus:outline-none
													focus:ring-2
													focus:ring-offset-2
													focus:ring-indigo-500
												"
												@click.stop="() => {}"
											>
												<span class="sr-only"
													>Open user menu</span
												>
												<svg
													fill="none"
													height="20"
													viewBox="0 0 20 20"
													width="20"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
												</svg>
											</MenuButton>
										</div>
										<transition
											enter-active-class="transition ease-out duration-200"
											enter-from-class="transform opacity-0 scale-95"
											enter-to-class="transform opacity-100 scale-100"
											leave-active-class="transition ease-in duration-75"
											leave-from-class="transform opacity-100 scale-100"
											leave-to-class="transform opacity-0 scale-95"
										>
											<MenuItems
												class="
													origin-top-right
													absolute
													right-0
													mt-2
													w-60
													rounded-md
													shadow-lg
													py-1
													bg-white
													ring-1
													ring-black
													ring-opacity-5
													focus:outline-none
													z-10
												"
											>
												<MenuItem
													v-slot="{ active }"
													v-if="isAdmin"
												>
													<div
														@click.stop="
															openAddStaffModel(
																person.id
															)
														"
														:class="
															active ? '' : ''
														"
														class="
															p-2
															px-4
															bg-white
															z-10
															text-gray-600
															hover:bg-neutral-50
															flex
															items-center
															gap-4
														"
													>
														<svg
															width="16"
															height="16"
															viewBox="0 0 16 16"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M1 14C1 14 0 14 0 13C0 12 1 9 6 9C11 9 12 12 12 13C12 14 11 14 11 14H1Z"
																fill="#94A3B8"
															/>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M6 8C7.65685 8 9 6.65685 9 5C9 3.34315 7.65685 2 6 2C4.34315 2 3 3.34315 3 5C3 6.65685 4.34315 8 6 8Z"
																fill="#94A3B8"
															/>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M13.5 5C13.7761 5 14 5.22386 14 5.5V7H15.5C15.7761 7 16 7.22386 16 7.5C16 7.77614 15.7761 8 15.5 8H14V9.5C14 9.77614 13.7761 10 13.5 10C13.2239 10 13 9.77614 13 9.5V8H11.5C11.2239 8 11 7.77614 11 7.5C11 7.22386 11.2239 7 11.5 7H13V5.5C13 5.22386 13.2239 5 13.5 5Z"
																fill="#94A3B8"
															/>
														</svg>

														<span class="mt-0.5"
															>Thêm Trị liệu
															viên</span
														>
													</div>
												</MenuItem>
												<MenuItem
													v-slot="{ active }"
													v-if="isAdmin"
												>
													<div
														@click.stop="
															inviting(
																person.id,
																person.full_name
															)
														"
														:class="
															active ? '' : ''
														"
														class="
															p-2
															px-4
															bg-white
															z-10
															text-gray-600
															hover:bg-neutral-50
															flex
															items-center
															gap-4
														"
													>
														<svg
															width="16"
															height="16"
															viewBox="0 0 16 16"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M1 14C1 14 0 14 0 13C0 12 1 9 6 9C11 9 12 12 12 13C12 14 11 14 11 14H1Z"
																fill="#94A3B8"
															/>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M6 8C7.65685 8 9 6.65685 9 5C9 3.34315 7.65685 2 6 2C4.34315 2 3 3.34315 3 5C3 6.65685 4.34315 8 6 8Z"
																fill="#94A3B8"
															/>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M13.5 5C13.7761 5 14 5.22386 14 5.5V7H15.5C15.7761 7 16 7.22386 16 7.5C16 7.77614 15.7761 8 15.5 8H14V9.5C14 9.77614 13.7761 10 13.5 10C13.2239 10 13 9.77614 13 9.5V8H11.5C11.2239 8 11 7.77614 11 7.5C11 7.22386 11.2239 7 11.5 7H13V5.5C13 5.22386 13.2239 5 13.5 5Z"
																fill="#94A3B8"
															/>
														</svg>

														<span class="mt-0.5"
															>Thêm Phụ
															huynh</span
														>
													</div>
												</MenuItem>
												<MenuItem v-slot="{ active }">
													<a
														:class="
															active ? '' : ''
														"
														:href="`/record/student/${person.id}/result`"
														class="
															p-2
															px-4
															bg-white
															z-10
															text-gray-600
															hover:bg-neutral-50
															flex
															items-center
															gap-4
														"
													>
														<svg
															fill="none"
															height="16"
															viewBox="0 0 16 16"
															width="16"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																clip-rule="evenodd"
																d="M2.66659 0C1.93021 0 1.33325 0.596953 1.33325 1.33333V14.6667C1.33325 15.403 1.93021 16 2.66659 16H13.3333C14.0696 16 14.6666 15.403 14.6666 14.6667V1.33333C14.6666 0.596954 14.0696 0 13.3333 0H2.66659ZM5.33325 3.33333C5.33325 2.96514 5.63173 2.66667 5.99992 2.66667H12.6666C13.0348 2.66667 13.3333 2.96514 13.3333 3.33333C13.3333 3.70152 13.0348 4 12.6666 4H5.99992C5.63173 4 5.33325 3.70152 5.33325 3.33333ZM5.99992 5.33333C5.63173 5.33333 5.33325 5.63181 5.33325 6C5.33325 6.36819 5.63173 6.66667 5.99992 6.66667H12.6666C13.0348 6.66667 13.3333 6.36819 13.3333 6C13.3333 5.63181 13.0348 5.33333 12.6666 5.33333H5.99992ZM5.33325 8.66667C5.33325 8.29848 5.63173 8 5.99992 8H12.6666C13.0348 8 13.3333 8.29848 13.3333 8.66667C13.3333 9.03486 13.0348 9.33333 12.6666 9.33333H5.99992C5.63173 9.33333 5.33325 9.03486 5.33325 8.66667ZM5.99992 10.6667C5.63173 10.6667 5.33325 10.9651 5.33325 11.3333C5.33325 11.7015 5.63173 12 5.99992 12H12.6666C13.0348 12 13.3333 11.7015 13.3333 11.3333C13.3333 10.9651 13.0348 10.6667 12.6666 10.6667H5.99992ZM3.99992 3.33333C3.99992 2.96514 3.70144 2.66667 3.33325 2.66667C2.96506 2.66667 2.66659 2.96514 2.66659 3.33333C2.66659 3.70152 2.96506 4 3.33325 4C3.70144 4 3.99992 3.70152 3.99992 3.33333ZM3.33325 5.33333C3.70144 5.33333 3.99992 5.63181 3.99992 6C3.99992 6.36819 3.70144 6.66667 3.33325 6.66667C2.96506 6.66667 2.66659 6.36819 2.66659 6C2.66659 5.63181 2.96506 5.33333 3.33325 5.33333ZM3.99992 8.66667C3.99992 8.29848 3.70144 8 3.33325 8C2.96506 8 2.66659 8.29848 2.66659 8.66667C2.66659 9.03486 2.96506 9.33333 3.33325 9.33333C3.70144 9.33333 3.99992 9.03486 3.99992 8.66667ZM3.33325 10.6667C3.70144 10.6667 3.99992 10.9651 3.99992 11.3333C3.99992 11.7015 3.70144 12 3.33325 12C2.96506 12 2.66659 11.7015 2.66659 11.3333C2.66659 10.9651 2.96506 10.6667 3.33325 10.6667Z"
																fill="#94A3B8"
																fill-rule="evenodd"
															/>
														</svg>

														<span class="mt-0.5"
															>Xem Kết quả đánh
															giá</span
														>
													</a></MenuItem
												>
												<MenuItem v-slot="{ active }">
													<a
														:class="
															active ? '' : ''
														"
														:href="`/plans/${person.id}`"
														class="
															p-2
															px-4
															bg-white
															z-10
															text-gray-600
															hover:bg-neutral-50
															flex
															items-center
															gap-4
														"
													>
														<svg
															fill="none"
															height="16"
															viewBox="0 0 16 16"
															width="16"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																clip-rule="evenodd"
																d="M11 2C11 1.44772 11.4477 1 12 1H14C14.5523 1 15 1.44772 15 2V14H15.5C15.7761 14 16 14.2239 16 14.5C16 14.7761 15.7761 15 15.5 15H0.5C0.223858 15 0 14.7761 0 14.5C0 14.2239 0.223858 14 0.5 14H1V11C1 10.4477 1.44772 10 2 10H4C4.55228 10 5 10.4477 5 11V14H6V7C6 6.44772 6.44772 6 7 6H9C9.55228 6 10 6.44772 10 7V14H11V2Z"
																fill="#94A3B8"
																fill-rule="evenodd"
															/>
														</svg>

														<span class="mt-0.5"
															>Xem Kế hoạch can
															thiệp</span
														>
													</a></MenuItem
												>
												<MenuItem
													v-slot="{ active }"
													v-if="
														isAdmin &&
														centerState !==
															'community'
													"
												>
													<div
														@click.stop="
															changeStudentStatus(
																person
															)
														"
														:class="
															active ? '' : ''
														"
														class="
															p-2
															px-4
															bg-white
															z-10
															text-gray-600
															hover:bg-neutral-50
															flex
															items-center
															gap-4
														"
													>
														<svg
															width="16"
															height="16"
															viewBox="0 0 16 16"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M5.6 7.8C5.048 7.8 4.6 8.248 4.6 8.8C4.6 9.352 5.048 9.8 5.6 9.8C6.152 9.8 6.6 9.352 6.6 8.8C6.6 8.248 6.152 7.8 5.6 7.8ZM10.4 7.8C9.848 7.8 9.4 8.248 9.4 8.8C9.4 9.352 9.848 9.8 10.4 9.8C10.952 9.8 11.4 9.352 11.4 8.8C11.4 8.248 10.952 7.8 10.4 7.8ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 7.768 1.616 7.536 1.64 7.312C3.528 6.472 5.024 4.928 5.808 3.016C7.256 5.064 9.64 6.4 12.336 6.4C12.96 6.4 13.56 6.328 14.136 6.192C14.304 6.76 14.4 7.368 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z"
																fill="#94A3B8"
															/>
															<path
																d="M6.4 11.1916C6.4 11.1916 7.89333 14.7916 9.6 11.1916H6.4Z"
																fill="#94A3B8"
															/>
														</svg>
														<span class="mt-0.5"
															>Đổi trạng
															thái</span
														>
													</div>
												</MenuItem>
											</MenuItems>
										</transition>
									</Menu>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div
				v-else
				class="
					w-full
					h-full
					mt-20
					flex
					items-center
					justify-center
					flex-col
				"
			>
				<img
					alt=""
					height="250"
					src="@/assets/images/illustrations/not_found.png"
					width="250"
				/>
				<div
					class="text-neutral-500 text-lg text-center"
					style="max-width: 700px"
				>
					Rất tiếc, chúng tôi không thể tìm thấy kết quả cho tìm kiếm
					của bạn.
				</div>
			</div>
		</div>
		<div v-else class="fixed top-1/2 left-1/2">
			<Loading />
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { _MODAL_TYPE as modalTypes, _MODAL_TYPE } from '@/enums';
import { useStudent } from '@/features/student/useStudent';
import { useAssignments } from '@/features/assignment/useAssignments';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useStore } from 'vuex';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import CreateStudent from '@/views/record/students/features/createStudent/CreateStudent.vue';
import Loading from '@/components/common/Loading.vue';
import StudentState from '@/components/common/StudentState.vue';
import CreateStaffModal from '@/views/record/studentDetail/features/staffList/features/createStaffModal/CreateStaffModal.vue';
import { useGetEmployees } from '@/views/record/employee/services/getEmployees';
import InviteParentPopup from '@/views/record/studentDetail/features/inviteParent/InviteParentPopup.vue';
import ChangeStatus from '@/views/record/students/features/ChangeStatus.vue';

const students = ref([]);
const studentData = ref(null);
const full_students = ref([]);
const staffsList = ref([]);
const count = ref({
	active: 0,
	closed: 0,
	day_care_classes: 0,
	graduated: 0,
	group_classes: 0,
	locked: 0,
	pending: 0,
	personal_classes: 0,
	stopped: 0,
	total: 0,
});
const modalIsOpen = ref(false);
const studentFullName = ref('');
const error = ref('');
const status = ref('');
const modalType = ref('');
const isLoading = ref(true);
const inProgress = ref(false);
const id = ref('');
const searchQuery = ref('');
const typing = ref(null);
const tabs = ref([
	{
		label: `Đang học`,
		value: 'active',
		type: 'active',
	},
	{
		label: `Học can thiệp`,
		value: 'active_with_personal_class',
		type: 'active',
	},
	{
		label: `Học bán trú`,
		value: 'active_with_day_care_class',
		type: 'active',
	},
	{
		label: `Học lớp nhóm`,
		value: 'active_with_group_class',
		type: 'active',
	},
	{
		label: `Đã khoá`,
		value: 'locked',
		type: 'locked',
	},
	{
		label: `Tốt nghiệp`,
		value: 'graduated',
		type: 'locked',
	},
	{
		label: `Tạm dừng`,
		value: 'closed',
		type: 'locked',
	},
	{
		label: `Xin nghỉ`,
		value: 'stopped',
		type: 'locked',
	},
]);
const tab = ref('active');
const store = useStore();
const { getStudents, createStudents, closeStudent } = useStudent();
const { getEmployees } = useGetEmployees();
const { createStaffAssignments } = useAssignments();
const isAdmin = computed(() => store.getters.isAdmin);
const studentsFilter = computed(() => students.value);
const centerState = computed(() => store.state.profile.center_state);
const isAdminAndAccountant = computed(() => store.getters.isAdminAndAccountant);

watch(status, (newValue, oldValue) => {
	if (oldValue == '') return;
	fetchStudents();
});
onMounted(() => {
	status.value = centerState.value === 'community' ? 'all' : 'active';
	fetchStudents();
	fetchStaffs();
});
const changeStatus = (value) => {
	status.value = value;
	tab.value = value;
};
const changeStudentStatus = (student) => {
	studentData.value = student;
	modalIsOpen.value = true;
	modalType.value = _MODAL_TYPE.CHANGE;
};

const changeStatusStudent = (isActive = false, data) => {
	closeStudentSubmit({
		isActive: isActive,
		id: studentData.value.id,
		state: data || undefined,
	});
	modalIsOpen.value = false;
};

const closeStudentSubmit = async (value) => {
	const res = await closeStudent(value.id, value.isActive, value.state);
	if (res?.data) {
		fetchStudents();
	}
	if (res?.data?.message) {
		await store.dispatch('setAlertMessage', {
			message: res?.data?.message,
		});
	}
};
const createStaffSubmit = async (value) => {
	const res = await createStaffAssignments(
		id.value,
		value?.staff_id,
		value?.main
	);
	if (res?.data) {
		modalIsOpen.value = false;
		fetchStudents();
		await store.dispatch('setAlertMessage', {
			message: 'Thêm giáo viên thành công',
		});
	}
};

const openAddStaffModel = (data) => {
	id.value = data;
	modalIsOpen.value = true;
	modalType.value = _MODAL_TYPE.CREATE;
};
const inviting = (data, student_name) => {
	id.value = data;
	studentFullName.value = student_name;
	modalIsOpen.value = true;
	modalType.value = _MODAL_TYPE.INVITE;
};
const fetchStudents = async () => {
	let role =
		store.state.profile.staff.role === 'teacher' ? 'staff' : 'org_admin';
	const studentList = await getStudents(role, {
		state: status.value,
	});
	isLoading.value = false;
	count.value = studentList.count;
	students.value = studentList.data;
	full_students.value = studentList.data;
};

const openCreateModal = () => {
	modalIsOpen.value = true;
	modalType.value = _MODAL_TYPE.ADD;
};

const closeModal = () => {
	modalIsOpen.value = false;
};

const formatTextSearch = (text) => {
	if (!text) {
		return '';
	}
	return text.toLowerCase().trim();
};

const search = async () => {
	students.value = full_students.value.filter((s) => {
		return (
			formatTextSearch(s.full_name).includes(
				formatTextSearch(searchQuery.value)
			) ||
			formatTextSearch(s.name).includes(
				formatTextSearch(searchQuery.value)
			) ||
			formatTextSearch(s.unaccent_full_name).includes(
				formatTextSearch(searchQuery.value)
			) ||
			formatTextSearch(s.unaccent_name).includes(
				formatTextSearch(searchQuery.value)
			)
		);
	});
};

const searching = () => {
	clearInterval(typing.value);
	typing.value = setTimeout(() => {
		search();
	}, 1000);
};

const submitStudent = async (student) => {
	inProgress.value = true;
	const res = await createStudents({ student }, '');
	if (res?.data) {
		fetchStudents();
		modalIsOpen.value = false;
		await store.dispatch('setAlertMessage', {
			message: 'Thêm học sinh thành công',
		});
	}
	inProgress.value = false;
	if (res.errors) {
		error.value =
			res.errors.join('<br/>') || 'Đã có lỗi xảy ra. Vui lòng thử lại';
		await store.dispatch('setAlertMessage', {
			message: error,
			type: 'danger',
		});
	}
	if (res?.errors_by_field) {
		error.value = res?.errors_by_field['name'].join('<br/>');
	}
};

const fetchStaffs = async () => {
	if (isAdminAndAccountant.value) {
		const res = await getEmployees('', null);
		if (res?.data) {
			staffsList.value = res?.data.filter((item) => {
				return item?.state === 'active';
			});
		}
	}
};

const numberOfStudent = (data) => {
	let number =
		data == 'active'
			? count.value.active
			: data == 'active_with_personal_class'
			? count.value.personal_classes
			: data == 'active_with_day_care_class'
			? count.value.day_care_classes
			: data == 'active_with_group_class'
			? count.value.group_classes
			: data == 'locked'
			? count.value.locked
			: data == 'graduated'
			? count.value.graduated
			: data == 'closed'
			? count.value.closed
			: count.value.stopped;
	return number;
};
</script>

<style lang="scss" scoped>
@import '../record.scss';
.tab {
	min-width: max-content !important;
	width: max-content !important;
	border-radius: 16px;
	height: fit-content;
	font-size: 14px;
}
</style>
